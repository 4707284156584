(function ($) {
  'use strict';

  $(window.document).ready(function () {
    var mod = window.Modernizr;
    var device = '';

    // identification de l'écran
    var getDevice = function () {
      var winSize = $('#site_screen_type').css('font-family');
      winSize = winSize.replace(/"/g, '');
      device = winSize;
    };

    // SVG fallback
    if (!mod.svg) {
      var $svgImages = $('img[src$=".svg"]');
      $svgImages.each(function () {
        $image = $(this);
        var fallbackSRC = $image.attr('src').replace(/(.*\/)(.*)(\.svg$)/, '$1fallback-png/$2.png');
        $image.attr('src', fallbackSRC);
      });
    }

    // JqueryUI inner input Number
    var $inputSpinner = $('input[type="number"]');
    $inputSpinner.spinner();

    // Footer toggle menu
    var $footermenuLabel = $('.footer__nav .list-label');

    var footerMenuMobile = function() {
      if (device === 'screen-small' || device === 'screen-x-small') {
        $footermenuLabel.click( function(e) {
          e.preventDefault();
        })
      } else {
        $footermenuLabel.unbind('click');
      };
    };

    getDevice();
    footerMenuMobile();

    $(window).resize(function() {
      getDevice();
      footerMenuMobile();
    });

    (function scrollToAnchorOnLoad () {
      $(window).on('load', function () {
        var hash = window.document.location.hash;

        if (hash) {
          window.setTimeout(function () {
            window.location.href = hash;
          }, 10);
        }
      });
    }());

    // TODO : redmine 18709
    // (function fixStickyHeaderInputFocusMobile () {
    //   $('input').on('focus', function (event) {
    //     $(event.target).on('blur', function ())
    //   }
    // }());
  });
}(window.jQuery.noConflict()));
